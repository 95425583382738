import { FC, useState } from 'react';
import { HiOutlineSearch } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import { ITheme } from '@nirvana/ui-kit';
import { ApolloError } from '@apollo/client';
import Highlighter from 'react-highlight-words';
import {
  Autocomplete,
  InputBase,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  useCreateReportMutation,
  useFleetSearchQuery,
} from 'src/types/graphql-types';
import { analytics } from 'src/helpers';

const useStyles = makeStyles((theme: ITheme) => ({
  paper: {
    borderRadius: theme.shape.borderRadius,
    width: '448px',
  },
  popper: {
    left: '-16px !important',
    top: '8px !important',
  },
  option: {
    '&[data-focus="true"]': {
      backgroundColor: `${theme.palette.primary.extraLight} !important`,
    },
  },
}));

const HighlightText: FC = ({ children }) => (
  <Typography variant="body1" component="span" fontWeight="fontWeightSemiBold">
    {children}
  </Typography>
);

export default function SearchDot() {
  const classes = useStyles();
  const navigate = useNavigate();

  const [isValid, setIsValid] = useState(true);
  const [isNetworkError, setNetworkError] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const { data } = useFleetSearchQuery({
    variables: { searchText: inputValue },
  });

  const searchResults = data?.fetchFleetSafetySearch || [];

  const [createReport] = useCreateReportMutation({
    onError: (error: ApolloError) => {
      const networkError: any = error.networkError;
      if (networkError && networkError?.statusCode >= 500) {
        setNetworkError(true);
      } else {
        setIsValid(false);
      }
    },
    onCompleted: ({ createFleetSafetyReport }) => {
      if (createFleetSafetyReport) {
        navigate(`/${createFleetSafetyReport?.id}/overview`);
      } else {
        setIsValid(false);
      }
    },
  });

  function handleChange(e: any, value: string = '') {
    setInputValue(value);
    if (!isValid) {
      setIsValid(true);
      setNetworkError(false);
    }
  }

  function handleSubmit(dotNumber: string) {
    if (dotNumber) {
      analytics.trackEvent({
        event: analytics.SegmentEventTrack.SearchDOT,
        properties: {
          dotNumber,
        },
      });

      createReport({
        variables: { dotNumber },
      });
    }
  }

  return (
    <div className="w-full mb-6">
      <div className="py-2.5 px-4 flex bg-white rounded-md shadow-lg focus-within:ring-2 ring-indigo-500">
        <Autocomplete
          options={searchResults}
          sx={{ flex: 1, display: 'flex' }}
          freeSolo
          autoHighlight
          openOnFocus
          onChange={(e: any, value: any) => {
            e.preventDefault();
            if (e.type === 'keydown' && e.key === 'Enter') {
              handleSubmit(value?.dotNumber);
            } else {
              handleSubmit(value?.dotNumber);
            }
          }}
          onInputChange={handleChange}
          inputValue={inputValue}
          classes={{
            option: classes.option,
            paper: classes.paper,
            popper: classes.popper,
          }}
          renderInput={(params) => {
            const { InputProps, ...other } = params;

            return (
              <InputBase
                {...other}
                {...InputProps}
                startAdornment={
                  <div className="pr-2 mb-0.5">
                    <HiOutlineSearch fontSize="1.1rem" color="#878B95" />
                  </div>
                }
                placeholder="Enter fleet name or DOT number"
                type="text"
              />
            );
          }}
          getOptionLabel={() => {
            return inputValue;
          }}
          renderOption={(props, option, { inputValue }) => {
            return (
              <ListItem {...props} key={option.dotNumber} dense>
                <ListItemText
                  primary={
                    <div className="flex justify-between">
                      <Highlighter
                        searchWords={[inputValue]}
                        autoEscape={true}
                        textToHighlight={option.name}
                        highlightTag={HighlightText}
                      />
                      <span className="text-xs text-gray-400">
                        {option.state}
                      </span>
                    </div>
                  }
                  secondary={
                    <Highlighter
                      searchWords={[inputValue]}
                      autoEscape={true}
                      textToHighlight={option.dotNumber}
                      highlightTag={HighlightText}
                    />
                  }
                  secondaryTypographyProps={{
                    color: 'text.hint',
                    variant: 'caption',
                  }}
                  sx={{ margin: 0 }}
                />
              </ListItem>
            );
          }}
        />
      </div>
      {!isValid ? (
        <p className="h-6 mt-2 text-sm text-red-600">
          {isNetworkError
            ? 'We are facing some issues. Please check back in a day.'
            : 'This fleet is not available in the database'}
        </p>
      ) : (
        <p className="w-full h-6" />
      )}
    </div>
  );
}
