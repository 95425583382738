import { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HiChevronDown } from 'react-icons/hi';
import { Branding, BrandingTypes, Menu, Show } from '@nirvana/ui-kit';
import useAuth from 'src/hooks/useAuth';
import { IconButton } from '@material-ui/core';
import { FiSidebar } from 'react-icons/fi';
import InviteModal from './components/InviteModal';
import CompleteSignUpBanner from './components/CompleteSignUpBanner';
import { useShowInvite } from './hooks/useShowInvite';
export default function Navbar({
  handleDrawerToggle,
}: {
  handleDrawerToggle?: () => void;
}) {
  const { logout, user } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout().then(() => {
      navigate('/', { replace: true });
    });
  };

  const showInvite = useShowInvite();

  const trigger = useMemo(
    () => (
      <button className="flex items-center space-x-3">
        <span className="flex items-center justify-center w-8 h-8 font-mono font-extrabold text-white bg-blue-500 rounded-full">
          {user?.name.charAt(0).toUpperCase()}
        </span>
        <span className="hidden text-sm font-medium sm:block">
          {user?.name}
        </span>
        <HiChevronDown />
      </button>
    ),
    [user],
  );

  return (
    <nav className="sticky top-0 z-20 flex items-center justify-between w-full px-8 py-3 bg-white border-b">
      <Show when={handleDrawerToggle}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          data-testid="sidebar-toggle"
          className="mr-2 md:hidden"
        >
          <FiSidebar />
        </IconButton>
      </Show>
      <Link className="flex items-center" to="/">
        <Branding brandingType={BrandingTypes.SafetyInverted} />
      </Link>
      <div className="flex items-center gap-4">
        <div className="hidden md:block">
          <InviteModal />
        </div>
        <CompleteSignUpBanner />
        <Menu trigger={trigger}>
          <Show when={showInvite}>
            <div className="md:hidden">
              <InviteModal variant="outlined" />
            </div>
          </Show>
          <Menu.Item onClick={handleLogout}>Logout</Menu.Item>
        </Menu>
      </div>
    </nav>
  );
}
