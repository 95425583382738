import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTable, Column, useSortBy } from 'react-table';
import { IconButton } from '@material-ui/core';
import { TableSkeletonLoader, SortableHeader, Chip } from '@nirvana/ui-kit';

import {
  useCreateReportMutation,
  useFleetStarMutation,
  useSearchHistoryQuery,
} from 'src/types/graphql-types';
import { ReactComponent as StarIcon } from 'src/assets/icons/star.svg';
import { ReactComponent as DeleteIcon } from 'src/assets/icons/delete.svg';
import { ReactComponent as StarredReportIcon } from 'src/assets/icons/starred-report.svg';
import { BlankStateRegular as BlankState } from 'src/components/blankState';
import { analytics } from 'src/helpers';
import { MOCK_DOTS } from 'src/constants';

const defaultColumn = {
  Header: SortableHeader,
};

export default function RecentSearch() {
  const navigate = useNavigate();
  const { data, loading, refetch } = useSearchHistoryQuery();

  const [fleetStarMutation] = useFleetStarMutation({
    onCompleted: () => refetch(),
  });

  const [createReport] = useCreateReportMutation({
    variables: { dotNumber: '123456' },
    onCompleted: ({ createFleetSafetyReport }) => {
      if (createFleetSafetyReport) {
        navigate(`/${createFleetSafetyReport?.id}/overview`);
      }
    },
  });

  const tableData = useMemo(() => {
    return (
      data?.fleetSafetyReports.edges
        .map((edge) => edge.node)
        .filter(
          (report) => report.starred && !MOCK_DOTS.includes(report.dotNumber),
        ) ?? []
    );
  }, [data]);

  const recentSearchColumns: Array<
    Column<{ id: any; name: any; dotNumber: any; createdAt: any }>
  > = useMemo(
    () => [
      { label: 'Fleet name', accessor: 'name' },
      { label: 'DOT number', accessor: 'dotNumber' },
      {
        label: '',
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div className="text-right">
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();

                  analytics.trackEvent({
                    event: analytics.SegmentEventTrack.StarredDOTDelete,
                    properties: {
                      dotNumber: row.original.dotNumber,
                      reportId: row.original.id,
                    },
                  });

                  fleetStarMutation({
                    variables: { reportId: row.original.id, markStar: false },
                  });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
    ],
    [fleetStarMutation],
  );

  const { getTableProps, headerGroups, getTableBodyProps, rows, prepareRow } =
    useTable(
      { data: tableData, columns: recentSearchColumns, defaultColumn },
      useSortBy,
    );

  const tableHead = useMemo(
    () => (
      <thead>
        {headerGroups.map((headerGroup, index) => (
          <tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                key={index}
                className="px-6 py-2 text-xs font-light text-left bg-primary-extraLight"
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
    ),
    [headerGroups],
  );

  let tableContent;

  if (loading) {
    tableContent = (
      <table {...getTableProps()} className="w-full">
        {tableHead}
        <tbody>
          <TableSkeletonLoader columns={recentSearchColumns.length} />
        </tbody>
      </table>
    );
  } else if (!data) {
    tableContent = (
      <>
        <BlankState
          icon={<StarredReportIcon />}
          text="You have not starred any reports yet"
          subText="You can star any of the reports to access them quickly right from here"
        />
      </>
    );
  } else {
    tableContent = (
      <table {...getTableProps()} className="w-full">
        {tableHead}
        <tbody {...getTableBodyProps()}>
          <tr
            onClick={() => createReport()}
            className="text-sm cursor-pointer text-text-primary hover:bg-primary-extraLight/80"
          >
            <td className="px-6 py-2 font-normal text-text-secondary">
              NIRVANA TRUCKING
            </td>
            <td className="px-6 py-2 font-normal text-text-secondary">
              123456
            </td>
            <td className="px-6 py-2 font-normal text-right text-text-secondary">
              <Chip
                color="info"
                label="Sample Report"
                className="cursor-pointer"
              />
            </td>
          </tr>
          {rows.map((row) => {
            prepareRow(row);
            const { id: reportId, dotNumber } = row.original;
            const { key, ...restRowProps } = row.getRowProps();
            return (
              <tr
                key={key}
                {...restRowProps}
                className="text-sm cursor-pointer text-text-primary hover:bg-primary-extraLight/80"
                onClick={() => {
                  analytics.trackEvent({
                    event: analytics.SegmentEventTrack.StarredDOTView,
                    properties: {
                      reportId,
                      dotNumber,
                    },
                  });
                  navigate(`/${reportId}/overview`);
                }}
              >
                {row.cells.map((cell, index) => (
                  <td
                    {...cell.getCellProps()}
                    key={index}
                    className="px-6 py-2 font-normal text-text-secondary"
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return (
    <div className="relative z-10 flex flex-col flex-1 w-full py-4 mx-auto overflow-hidden bg-white border rounded-lg shadow md:px-6 border-primary-extraLight">
      <div className="flex items-center mb-8 ml-4 md:ml-0">
        <StarIcon />
        <p className="ml-2 text-xl font-semibold">Starred reports</p>
      </div>
      <div className="flex-1 overflow-hidden">
        <div className="flex flex-col h-full overflow-y-auto">
          {tableContent}
        </div>
      </div>
    </div>
  );
}
