import { alpha, colors } from '@material-ui/core';

export const light = {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontWeight: 400,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 10,
          boxShadow: '0px 4px 6px rgba(128, 145, 196, 0.16)',
          border: '1px solid #E6E7EF',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRight: 0,
          boxShadow: '0px 4px 6px rgba(128, 145, 196, 0.16)',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: colors.common.white,
          '&.MuiInputBase-multiline': {
            padding: 0,
          },
        },
        input: {
          padding: '11px',
          height: '18px',
        },
        inputMultiline: {
          padding: '12px 16px',
          height: 'auto',
        },
        notchedOutline: {
          borderColor: '#D7D8DB',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          '&.MuiOutlinedInput-root ': {
            padding: 0,

            '& .MuiAutocomplete-input': {
              padding: '11px',
              height: '18px',
            },
          },
        },
      },
    },

    MuiFormHelperText: {
      styleOverrides: {
        root: {
          // TODO: Try to use these colors from palette
          color: '#878B95',
          fontWeight: 400,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#00A0FF',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedSizeLarge: {
          lineHeight: 1.6,
        },
        root: {
          borderRadius: 5,
          padding: '8px 18px',
          textTransform: 'capitalize',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
          },
        },
        outlinedPrimary: {
          backgroundColor: '#fff',

          '&.MuiButton-outlinedPrimary': {
            backgroundColor: colors.common.white,
          },

          '&.Mui-disabled': {
            color: '#3350A1',
            borderColor: '#3350A1',
            opacity: 0.4,
          },
        },
        containedPrimary: {
          border: '1px solid #3350A1',
          '&:not(:hover, .Mui-disabled)': {
            backgroundColor: '#3350A1',
          },

          '&.Mui-disabled': {
            color: alpha(colors.common.white, 0.6),

            '& .MuiButton-endIcon': {
              opacity: 0.6,
            },
          },
        },
        startIcon: {
          marginRight: 12,
        },
        iconSizeMedium: {
          '& .MuiSvgIcon-root': {
            fontSize: 14,
            marginTop: 2,
          },
        },
        iconSizeLarge: {
          '& .MuiSvgIcon-root': {
            fontSize: 14,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '&:focus': {
            backgroundColor: '#ffffff',
          },
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          backgroundColor: alpha('#D7D8DB', 0.9),
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: '#D7D8DB',
          },
        },
      },
    },
    MuiLinearProgressBar: {
      styleOverrides: {
        root: {
          height: 8,
          width: '30%',
          borderRadius: 5,
          marginTop: 5,
        },
        bar: {
          borderRadius: 5,
          backgroundColor: '#FFB900',
        },
        colorPrimary: {
          backgroundColor: 'rgba(255, 227, 153, 0.34)',
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 960,
      lg: 1280,
      xl: 1536,
      xxl: 1920,
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 700,
      fontSize: 64,
      lineHeight: 1.2,
      letterSpacing: '-1.5px',
    },
    h2: {
      fontWeight: 600,
      fontSize: 44,
      lineHeight: 1.3,
      letterSpacing: '-0.01562em',
    },
    h3: {
      fontWeight: 600,
      fontSize: 36,
      lineHeight: 1.3,
      letterSpacing: '-0.01562em',
    },
    h4: {
      fontWeight: 500,
      fontSize: 28,
      lineHeight: 1.2,
      letterSpacing: '-0.01562em',
    },
    h5: {
      fontWeight: 500,
      fontSize: 20,
      lineHeight: 1.2,
      letterSpacing: '-0.01562em',
    },
    h6: {
      fontWeight: 600,
      fontSize: 16,
      lineHeight: 1.5,
      letterSpacing: '0.5',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '0.15',
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '0.15',
    },
    body1: {
      fontWeight: 500,
      fontSize: 14,
      letterSpacing: '0.15',
    },
    body2: {
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '0.15',
    },
    caption: {
      fontWeight: 400,
      fontSize: 12,
      letterSpacing: '0.15',
    },
    overline: {
      fontWeight: 400,
      fontSize: 10,
      lineHeight: 2,
      letterSpacing: '0.15',
      textTransform: 'none',
    },
    button: {
      fontWeight: 400,
      fontSize: 14,
      letterSpacing: '0.15',
    },
  },
  shape: {
    borderRadius: 5,
  },
  palette: {
    type: 'light',
    background: {
      default: '#FBFBFB',
      paper: colors.common.white,
    },
    divider: '#D7D8DB',
    overlay: '#00103C',
    primary: {
      main: '#3350A1',
      dark: '#00248A',
      light: '#8091C4',
      extraLight: '#F3F4FA',
      tint1: '#FAFBFF',
      tint2: '#D7DCFE',
      tint3: '#E5E7F1',
      track: '#E3E6F6',
    },
    secondary: {
      main: '#040C21',
      dark: '#4A505F',
      light: '#8C8F99',
      tint1: '#FCFCFF',
    },
    text: {
      primary: '#363D4D',
      secondary: '#4A505F',
      disabled: '#D7D8DB',
      hint: '#878B95',
      lightGrey: '#8A8A8E',
    },
    info: {
      main: '#00A0FF',
      dark: '#00A0FF',
      light: '#FAFDFF',
      extraLight: '#EEF8FF',
    },
    success: {
      main: '#1ED05A',
      dark: '#25B255',
      light: '#FAFEFC',
      extraLight: '#E5FFEE',
      extraDark: '#1B5A2D',
    },
    error: {
      main: '#FA3252',
      dark: '#FA3252',
      light: '#FFFBFC',
      extraLight: '#FFF1F4',
      tint1: '#FFE3E7',
      tint2: '#FFF5F7',
      tint3: '#FDAAB7',
    },
    warning: {
      main: '#FF823C',
      dark: '#FF823C',
      light: '#FFFCFB',
      extraLight: '#FFF7F4',
      tint1: '#FFF4EE',
      tint2: '#FFC4A0',
      tint3: '#FEC6CF',
    },
    gold: {
      main: '#FFB900',
      light: '#FFDC80',
    },
    tint: {
      dark: '#00248A',
      main: '#3350A1',
      extraLight: '#8091C4',
    },
    grey: {
      50: '#F5F5F6',
      100: '#F5F5F6',
      200: '#D7D8DB',
      300: '#D7D8DB',
      400: '#878B95',
      500: '#878B95',
      600: '#4A505F',
      700: '#4A505F',
      800: '#363D4D',
      900: '#363D4D',
    },
    border: {
      main: '#E6E7EF',
    },
    action: {
      disabledBackground: '#7B94DB',
      disabledOpacity: 0.8,
    },
  },
};
