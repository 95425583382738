import { Navigate, RouteObject } from 'react-router-dom';

import AppShell from 'src/components/app-shell';
import { AuthGuard } from 'src/components/guards';
import Insights from 'src/components/insights';

import {
  Auth,
  ForgotPassword,
  VerificationEmail,
  ResetPassword,
  Login,
} from 'src/pages/auth';
// import Drivers from 'src/pages/drivers';
import Flags from 'src/pages/flags';
import Heatmap from 'src/pages/heatmap';
import Home from 'src/pages/home';
import Overview from 'src/pages/overview';
import Perks from 'src/pages/perks';
import Profile from 'src/pages/profile';
import Recommendations from 'src/pages/recommendations';
import Scores from 'src/pages/scores';
import Vehicles from 'src/pages/vehicles';
import Violations from 'src/pages/violations';
import TelematicsComplete from 'src/pages/telematics-complete';

import { Feature, useFeatureEnabled } from 'src/helpers/feature-flags';
import { Training } from 'src/pages/training';
import SignUp from 'src/pages/auth/components/signUp/sign-up';
import { TelematicsSignUp } from 'src/pages/auth/TelematicsSignUp';
import { Claims } from 'src/pages/claims/Claims';

export const GetRoutes = (): RouteObject[] => {
  const reportIdChildren = [
    { path: '/:reportId', element: <Navigate to="overview" /> },
    { path: 'overview', element: <Overview /> },
    { path: 'profile', element: <Profile /> },
    { path: 'recommendations', element: <Recommendations /> },
    { path: 'flags', element: <Flags /> },
    {
      path: 'insights',
      element: <Insights />,
      children: [
        { path: 'scores', element: <Scores /> },
        { path: 'violations', element: <Violations /> },
        { path: 'heatmap', element: <Heatmap /> },
        // { path: 'drivers', element: <Drivers /> },
        { path: 'vehicles', element: <Vehicles /> },
      ],
    },
    { path: 'training', element: <Training /> },
    { path: 'claims', element: <Claims /> },
  ];

  if (useFeatureEnabled(Feature.PERKS_TAB)) {
    reportIdChildren.push({ path: 'perks', element: <Perks /> });
  }

  return [
    {
      path: '/',
      element: (
        <Auth>
          <Login />
        </Auth>
      ),
    },
    {
      path: '/forgot-password',
      element: (
        <Auth>
          <ForgotPassword />
        </Auth>
      ),
    },
    {
      path: '/verification-email',
      element: (
        <Auth>
          <VerificationEmail />
        </Auth>
      ),
    },
    {
      path: '/reset-password',
      element: (
        <Auth>
          <ResetPassword />
        </Auth>
      ),
    },
    {
      path: '/sign-up/:shareId',
      element: (
        <Auth>
          <SignUp />
        </Auth>
      ),
    },
    {
      path: '/telematics-sign-up/:shareId',
      element: <TelematicsSignUp />,
    },
    {
      path: '/search',
      element: (
        <AuthGuard>
          <Home />
        </AuthGuard>
      ),
    },
    {
      path: '/telematics-connection-complete',
      element: (
        <AuthGuard>
          <TelematicsComplete />
        </AuthGuard>
      ),
    },
    {
      path: '/:reportId',
      element: (
        <AuthGuard>
          <AppShell />
        </AuthGuard>
      ),
      children: reportIdChildren,
    },
    { path: '/:reportId/insights', element: <Navigate to="scores" /> },
  ];
};
