import { ReactComponent as IconChart } from 'src/assets/icons/chart.svg';
import { ReactComponent as IconSummary } from 'src/assets/icons/summary.svg';
import { ReactComponent as IconRecommendation } from 'src/assets/icons/recommendation.svg';
import { ReactComponent as IconFlag } from 'src/assets/icons/flag.svg';
import { ReactComponent as IconInsight } from 'src/assets/icons/insight.svg';
import { ReactComponent as IconInsightActive } from 'src/assets/icons/insight-active.svg';
import { ReactComponent as IconPriceTag } from 'src/assets/icons/price-tag.svg';
import { ReactComponent as IconClaims } from 'src/assets/icons/claims.svg';
import { PiStudentLight } from 'react-icons/pi';

export type RouteBlock = {
  id: string;
  title: string;
  icon?: any;
  activeIcon?: any;
  defaultRoute: string;
  defaultExpanded?: boolean;
  routes?: Array<RouteBlock>;
};

type GetPagesParams = {
  showPerksTab: Boolean;
  showTrainingTab: Boolean;
  isFleetUser: Boolean;
  showClaims: Boolean;
};

export const getPages = (
  reportId: string,
  { showPerksTab, showTrainingTab, isFleetUser, showClaims }: GetPagesParams,
): Array<RouteBlock> => [
  {
    id: 'overview',
    title: 'Overview',
    icon: <IconChart className="fill-icon-default" />,
    activeIcon: <IconChart className="fill-icon-active" />,
    defaultRoute: `/${reportId}/overview`,
    defaultExpanded: false,
  },
  {
    id: 'profile',
    title: 'Profile',
    icon: <IconSummary className="fill-icon-default" />,
    activeIcon: <IconSummary className="fill-icon-active" />,
    defaultRoute: `/${reportId}/profile`,
    defaultExpanded: false,
  },
  {
    id: 'recommendations',
    title: 'Recommendations',
    icon: <IconRecommendation className="fill-icon-default" />,
    activeIcon: <IconRecommendation className="fill-icon-active" />,
    defaultRoute: `/${reportId}/recommendations`,
    defaultExpanded: false,
  },
  ...(!isFleetUser
    ? [
        {
          id: 'flags',
          title: 'Flags',
          icon: <IconFlag stroke="#4A505F" />,
          activeIcon: <IconFlag stroke="#00248A" />,
          defaultRoute: `/${reportId}/flags`,
          defaultExpanded: false,
        },
      ]
    : []),
  {
    id: 'insights',
    title: 'Insights',
    icon: <IconInsight />,
    activeIcon: <IconInsightActive />,
    defaultRoute: `/${reportId}/insights/scores`,
    defaultExpanded: true,
    routes: [
      {
        id: 'scores',
        title: 'Scores',
        icon: null,
        activeIcon: null,
        defaultRoute: `/${reportId}/insights/scores`,
      },
      {
        id: 'violations',
        title: 'Violations',
        icon: null,
        activeIcon: null,
        defaultRoute: `/${reportId}/insights/violations`,
      },
      {
        id: 'heatmap',
        title: 'Heatmap',
        icon: null,
        activeIcon: null,
        defaultRoute: `/${reportId}/insights/heatmap`,
      },
      // {
      //   id: 'drivers',
      //   title: 'Drivers',
      //   icon: null,
      //   activeIcon: null,
      //   defaultRoute: `/${reportId}/insights/drivers`,
      // },
      {
        id: 'vehicles',
        title: 'Vehicles',
        icon: null,
        activeIcon: null,
        defaultRoute: `/${reportId}/insights/vehicles`,
      },
    ],
  },
  ...(showPerksTab
    ? [
        {
          id: 'perks',
          title: 'Perks',
          icon: (
            <IconPriceTag
              className="fill-icon-default"
              style={{ height: '20px', width: '20px' }}
            />
          ),
          activeIcon: (
            <IconPriceTag
              className="fill-icon-active"
              style={{ height: '20px', width: '20px' }}
            />
          ),
          defaultRoute: `/${reportId}/perks`,
          defaultExpanded: false,
        },
      ]
    : []),
  ...(showTrainingTab
    ? [
        {
          id: 'training',
          title: 'Training',
          icon: (
            <PiStudentLight
              className="fill-icon-default"
              style={{ height: '20px', width: '20px' }}
            />
          ),
          activeIcon: (
            <PiStudentLight
              className="fill-icon-active"
              style={{ height: '20px', width: '20px' }}
            />
          ),
          defaultRoute: `/${reportId}/training`,
          defaultExpanded: false,
        },
      ]
    : []),
  ...(showClaims
    ? [
        {
          id: 'claims',
          title: 'Claims',
          icon: <IconClaims className="stroke-icon-default" />,
          activeIcon: <IconClaims className="stroke-icon-active" />,
          defaultRoute: `/${reportId}/claims`,
          defaultExpanded: false,
        },
      ]
    : []),
];
