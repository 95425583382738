import { useState } from 'react';
import clsx from 'clsx';
import { useParams } from 'react-router-dom';
import { Button, styled, CircularProgress } from '@material-ui/core';
import { debounce, Show, Tooltip } from '@nirvana/ui-kit';
import useSnackbar from 'src/hooks/useSnackbar';

import useAuth from 'src/hooks/useAuth';
import { analytics } from 'src/helpers';
import {
  useShareSafetyReportMutation,
  usePrintSafetyReportMutation,
} from 'src/types/graphql-types';
import { ReactComponent as ShareIcon } from 'src/assets/icons/share-icon.svg';
import { ReactComponent as DownloadIcon } from 'src/assets/icons/download-icon.svg';
import { Feature, useFeatureEnabled } from 'src/helpers/feature-flags';
import Zones from '../../components/zones';
import ShareModal from './components/share-modal';
import BasicScores from './components/basic-scores';
import PreTelematicsBanner from './components/pre-telematics';
import NirvanaSafetyScore from './components/nirvana-safety-score';

const IconButton = styled(Button)({
  minWidth: 0,
  padding: '12px',
});

/** DownloadUri
 * Downloads a file from a url using the given filename.
 * see https://stackoverflow.com/questions/11620698/how-to-trigger-a-file-download-when-clicking-an-html-button-or-javascript/49917066#49917066
 */
function downloadUri(url: string, filename: string): void {
  fetch(url)
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
}

export default function Overview() {
  const { user } = useAuth();
  const { reportId = '' } = useParams();
  const pushSnackbar = useSnackbar();
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const isAgentUser =
    user?.userType === 'agent' || user?.userType === 'nirvana';
  const shouldShowDownloadButton = useFeatureEnabled(Feature.REPORT_DOWNLOAD);
  const [shareSafetyReport, { data, loading }] = useShareSafetyReportMutation({
    variables: { reportID: reportId },
  });

  const [printSafetyReport, { loading: printLoading }] =
    usePrintSafetyReportMutation({
      variables: { reportID: reportId },
      onCompleted: ({ printFleetSafetyReport }) => {
        const url = printFleetSafetyReport?.url;
        if (url) {
          downloadUri(url, 'FleetSafetyReport.pdf');
        }
      },
      onError: () => {
        pushSnackbar(
          'Error Generating PDF',
          'Something went wrong when generating your pdf report.',
          { variant: 'error' },
        );
      },
    });

  async function handleShareModalOpen() {
    await shareSafetyReport();
    setShareModalOpen(true);
  }

  function handleShareModalClose() {
    setShareModalOpen(false);
  }

  function shareHover() {
    analytics.trackEvent({
      event: analytics.SegmentEventTrack.ShareDOT,
    });
  }

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <p className="text-3xl font-bold text-gray-800">Overview</p>
        <div className="flex items-center space-x-3">
          <Tooltip
            title="Get a publicly visible share URL for this safety report."
            placement="left"
          >
            <span
              onMouseOver={debounce(shareHover, 2000)}
              className={clsx({ 'cursor-not-allowed': !isAgentUser })}
            >
              <IconButton
                variant="outlined"
                disabled={!isAgentUser}
                onClick={handleShareModalOpen}
              >
                <Show when={!loading} fallback={<CircularProgress size={16} />}>
                  <ShareIcon className="w-4 h-4" />
                </Show>
              </IconButton>
            </span>
          </Tooltip>
          <Show when={shouldShowDownloadButton}>
            <IconButton
              variant="outlined"
              onClick={() => printSafetyReport()}
              disabled={printLoading}
            >
              <Show
                when={!printLoading}
                fallback={<CircularProgress size={16} />}
              >
                <DownloadIcon className="w-4 h-4" />
              </Show>
            </IconButton>
          </Show>
        </div>
      </div>

      <PreTelematicsBanner />
      <NirvanaSafetyScore />
      <BasicScores />
      <Zones />
      <ShareModal
        open={shareModalOpen}
        shareID={data?.shareSafetyReport?.id ?? ''}
        onClose={handleShareModalClose}
      />
    </>
  );
}
