import * as React from 'react';
import {
  Tooltip as MuiTooltip,
  TooltipProps,
  makeStyles,
} from '@material-ui/core';
import { ITheme } from '../../assets/themes';

const useStyles = makeStyles((theme: ITheme) => ({
  arrow: {
    color: theme.palette.text.primary,
  },
  tooltip: {
    backgroundColor: theme.palette.text.primary,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    borderRadius: theme.typography.pxToRem(5),
    fontSize: 10,
    maxWidth: 'none',
    width: 'auto',
  },
}));

interface ITooltipProps extends TooltipProps {}

export default function Tooltip({ children, ...rest }: ITooltipProps) {
  const classes = useStyles();
  return (
    <MuiTooltip
      {...rest}
      arrow
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
    >
      {children}
    </MuiTooltip>
  );
}
