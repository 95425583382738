import { InitialFormData } from './signUpForm';

export interface FieldState {
  value: string;
  error: string | null;
  dirty: boolean;
}

export interface Form {
  firstName: FieldState;
  lastName: FieldState;
  email: FieldState;
  password: FieldState;
  confirmPassword: FieldState;
}

const emptyFieldState = (initial?: string | undefined) => ({
  value: initial || '',
  error: null,
  dirty: false,
});

export const emptyForm = (initialValues: InitialFormData): Form => ({
  firstName: emptyFieldState(initialValues?.firstName),
  lastName: emptyFieldState(initialValues?.lastName),
  email: emptyFieldState(initialValues?.email),
  password: emptyFieldState(),
  confirmPassword: emptyFieldState(),
});

type FormError = string | null;
export type Validator = (form: Form) => FormError;

const makeValidator =
  (
    field: keyof Form,
    predicate: (value: string) => boolean,
    errorMessage: string,
  ): Validator =>
  (form: Form): FormError => {
    if (!form[field].dirty) return null;
    return predicate(form[field].value) ? null : errorMessage;
  };

const notBlank = (value: string) => !!value.trim();

const validateFirstName = makeValidator(
  'firstName',
  notBlank,
  'First name is required',
);

const validateLastName = makeValidator(
  'lastName',
  notBlank,
  'Last name is required',
);

const validateEmail = makeValidator('email', notBlank, 'Email is required');

const validateEmailFormat = makeValidator(
  'email',
  (value) => /\S+@\S+\.\S+/.test(value),
  'Invalid email format',
);

const validatePassword = makeValidator(
  'password',
  notBlank,
  'Password is required',
);

const validatePasswordComplexity = makeValidator(
  'password',
  (value) => value.length >= 8,
  'Password must be at least 8 characters long',
);

export const validateConfirmPassword: Validator = (form) => {
  if (!form.confirmPassword.dirty) return null;
  return form.confirmPassword.value === form.password.value
    ? null
    : 'Passwords do not match';
};

export const validateSignInForm = (form: Form): Form => {
  const validators: { [key in keyof Form]: Validator[] } = {
    firstName: [validateFirstName],
    lastName: [validateLastName],
    email: [validateEmail, validateEmailFormat],
    password: [validatePassword, validatePasswordComplexity],
    confirmPassword: [validateConfirmPassword],
  };
  return Object.entries(validators).reduce(
    (formState, [field, fieldValidators]) => {
      const error: FormError = fieldValidators.reduce(
        (error: FormError, validator: Validator): FormError =>
          error === null ? validator(form) : error,
        null,
      );
      const fieldName = field as keyof Form;
      return { ...formState, [field]: { ...form[fieldName], error } };
    },
    form,
  );
};
