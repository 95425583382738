import * as React from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Grid,
  Typography,
  makeStyles,
  Box,
  IconButton,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';

import { Dialog } from '@nirvana/ui-kit';

import { ITheme } from '../../assets/themes';
import FileUploadIcon from '../../assets/icons/file-upload.svg';
import Button from './default';
import { FileList } from './FileList';

export interface FileUploadProgress {
  total?: number;
  loaded?: number;
  status?: 'loading' | 'succeeded' | 'failed' | 'cancelled';
  updatedAt?: number;
  handle: string;
  name: string;
}

const useStyles = makeStyles((theme: ITheme) => ({
  closeButton: {
    position: 'absolute',
    top: theme.spacing(0),
    right: theme.spacing(0),
  },
  wrapper: {
    backgroundColor: theme.palette.primary.extraLight,
    borderRadius: 10,
    padding: theme.spacing(7),
    border: `1px dashed ${theme.palette.primary.light}`,
  },
  dialogPaper: {
    width: theme.typography.pxToRem(940),
  },
  dialogContent: {
    padding: theme.spacing(3, 0),
    position: 'relative',
    overflow: 'hidden',
    borderBottom: '1px solid #E6E7EF',
  },
  list: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}));

export interface FileUploadDialogProps {
  dialogOpen: boolean;
  value: Array<FileUploadProgress>;
  onClose: () => void;
  onChange: (files: Array<any>) => void;
  onRemove: (progress?: FileUploadProgress, index?: number) => void;
  onCancel: (progress: FileUploadProgress, index: number) => void;
  onRetry: (progress: FileUploadProgress, index: number) => void;
  onDownload: (progress: FileUploadProgress, index: number) => void;
  title: string;
}

type FilesByStatus = {
  succeeded: FileUploadProgress[];
  failed: FileUploadProgress[];
};

const sortFilesByStatus = (
  attachments: FileUploadProgress[],
): FilesByStatus => {
  const filesByStatus: FilesByStatus = {
    succeeded: [],
    failed: [],
  };
  for (let i = 0, l = attachments.length; i < l; i += 1) {
    if (attachments[i].status === 'failed') {
      filesByStatus.failed.push(attachments[i]);
    } else {
      filesByStatus.succeeded.push(attachments[i]);
    }
  }

  filesByStatus.succeeded.sort(
    (a: FileUploadProgress, b: FileUploadProgress) => {
      if (a.updatedAt === b.updatedAt) {
        return 0;
      } else if (!a.updatedAt) {
        return 1;
      } else if (!b.updatedAt) {
        return -1;
      }

      return a.updatedAt < b.updatedAt ? 1 : -1;
    },
  );

  return filesByStatus;
};

export const FileUploadDialog = ({
  dialogOpen,
  onClose,
  value,
  onChange,
  onRemove,
  onCancel,
  onRetry,
  onDownload,
  title,
}: FileUploadDialogProps) => {
  const classes = useStyles();
  const attachments: FileUploadProgress[] = value;

  const handleDrop = React.useCallback(
    (acceptedFiles) => {
      onChange(acceptedFiles);
    },
    [onChange],
  );

  const { getInputProps, getRootProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: handleDrop,
  });

  const filesByStatus = sortFilesByStatus(attachments);

  const handleClose = () => {
    // Remove all failed files
    filesByStatus.failed.forEach(onRemove);

    onClose();
  };

  return (
    <Dialog
      title=""
      open={dialogOpen}
      maxWidth="md"
      onClose={handleClose}
      primaryAction={
        <Button color="primary" variant="contained" onClick={handleClose}>
          Done
        </Button>
      }
      disableBackdropClose
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <Box className={classes.dialogContent}>
        <IconButton
          className={classes.closeButton}
          onClick={handleClose}
          edge="end"
          size="small"
        >
          <Close />
        </IconButton>
        <Grid container direction="column" spacing={3}>
          <Grid item container alignItems="center" flexWrap="nowrap">
            <Typography variant="h5" color="textPrimary">
              Upload Documents
            </Typography>
            &nbsp; &bull; &nbsp;
            <Typography
              variant="body1"
              color="text.hint"
              fontWeight="fontWeightSemiBold"
            >
              {title}
            </Typography>
          </Grid>
          <Grid item container spacing={3}>
            <Grid item xs={attachments && attachments.length ? 6 : 12}>
              <Box className={classes.wrapper} {...getRootProps()}>
                <input {...getInputProps()} />
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  spacing={3}
                >
                  <Grid item>
                    <img src={FileUploadIcon} alt="File Upload" />
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      textAlign="center"
                      fontWeight="fontWeightBold"
                    >
                      Drag and drop file(s) here{' '}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.hint"
                      textAlign="center"
                      mt={0.5}
                    >
                      Please upload in CSV/Excel or PDF format
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h6"
                      color="textPrimary"
                      textAlign="center"
                      fontWeight="fontWeightRegular"
                    >
                      or
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      size="small"
                      onClick={open}
                    >
                      Browse
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              item
              xs={attachments && attachments.length ? 6 : false}
              sx={{
                display: attachments && attachments.length ? 'block' : 'none',
              }}
            >
              <Box position="relative" height={'100%'}>
                <Box overflow="auto" pb={1} className={classes.list}>
                  {filesByStatus.failed && filesByStatus.failed.length ? (
                    <FileList
                      title={`Upload Failed (${filesByStatus.failed.length})`}
                      files={filesByStatus.failed}
                      onRemove={onRemove}
                      onCancel={onCancel}
                      onRetry={onRetry}
                      onDownload={onDownload}
                    />
                  ) : null}
                  {filesByStatus.succeeded && filesByStatus.succeeded.length ? (
                    <FileList
                      title={`Uploaded Files (${filesByStatus.succeeded.length})`}
                      files={filesByStatus.succeeded}
                      onRemove={onRemove}
                      onCancel={onCancel}
                      onRetry={onRetry}
                      onDownload={onDownload}
                    />
                  ) : null}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default FileUploadDialog;
