import * as React from 'react';
import { forwardRef, useState } from 'react';
import type { MouseEvent, ReactElement } from 'react';
import {
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuProps as MuiMenuProps,
  MenuItemProps as MuiMenuItemProps,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  menuRoot: {
    outline: 'none',
  },
  menuPaper: {
    minWidth: theme.typography.pxToRem(150),
    marginTop: '8px',
  },
  menuItemRoot: {
    outline: 'none',
  },
}));

type MenuProps = Omit<MuiMenuProps, 'open'> & {
  trigger: ReactElement;
};

const Menu = forwardRef<HTMLDivElement, MenuProps>(
  ({ trigger, ...menuProps }, ref) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const isMenuVisible = Boolean(anchorEl);

    function handleDropdownOpen(event: MouseEvent<HTMLElement>) {
      setAnchorEl(event.currentTarget);
    }

    function handleDropdownClose() {
      setAnchorEl(null);
    }

    return (
      <>
        {React.cloneElement(trigger, {
          ...trigger.props,
          onClick: handleDropdownOpen,
        })}
        <MuiMenu
          ref={ref}
          anchorEl={anchorEl}
          open={isMenuVisible}
          getContentAnchorEl={null}
          onClose={handleDropdownClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          classes={{ root: classes.menuRoot, paper: classes.menuPaper }}
          {...menuProps}
        >
          {React.Children.toArray(menuProps.children).map((child) => {
            return React.cloneElement(child as ReactElement, {
              ...(child as ReactElement).props,
              onClick: () => {
                handleDropdownClose();
                (child as ReactElement).props?.onClick();
              },
            });
          })}
        </MuiMenu>
      </>
    );
  },
);

export default Menu;

export const MenuItem = forwardRef<HTMLLIElement, MuiMenuItemProps>(
  ({ ...menuItemProps }, ref) => {
    const classes = useStyles();
    return (
      <MuiMenuItem
        classes={{ root: classes.menuItemRoot }}
        ref={ref}
        {...menuItemProps}
      />
    );
  },
);
