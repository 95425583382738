import { useEffect } from 'react';
import LogRocket from 'logrocket';
import useAuth from 'src/hooks/useAuth';

export default function Logrocket() {
  const { user } = useAuth();

  useEffect(() => {
    if (user) {
      LogRocket.init(import.meta.env.VITE_LOGROCKET_KEY);
      LogRocket.identify(user.id, { name: user.name, email: user.email });
    }
  }, [user]);

  return null;
}
