import { useParams } from 'react-router-dom';
import { Feature, useFeatureValue } from 'src/helpers/feature-flags';
import { useDotNumberQuery } from 'src/types/graphql-types';

export const useDOTSpecificFeature = (
  feature: Feature,
  { allowUserOverride = false } = {},
) => {
  const { reportId = '' } = useParams();

  const { data, loading } = useDotNumberQuery({
    variables: { reportId },
  });

  const currentDot = data?.fleetSafetyReport?.dotNumber;

  const flagValue = useFeatureValue(feature);

  let enabled = false;

  const isString = typeof flagValue === 'string';
  if (!isString) {
    console.warn('Feature value is not a string');
  }

  if (allowUserOverride && flagValue === 'all') {
    enabled = true;
  }

  if (currentDot && isString && flagValue.includes(currentDot)) {
    enabled = true;
  }

  return {
    enabled,
    loading,
  };
};
