import { CircularProgress } from '@material-ui/core';
import { UserProfileResponse } from '@nirvana/api/auth';
import { storage } from '@nirvana/ui-kit';
import React, {
  createContext,
  useCallback,
  useEffect,
  useReducer,
} from 'react';
import { useIntercom } from 'react-use-intercom';
import { fetchUserProfile } from 'src/pages/auth/queries';

import {
  AUTH_TOKEN_STORAGE_KEY,
  SHARE_TOKEN_STORAGE_KEY,
  SHAREID_QUERY_PARAM,
} from 'src/constants';
import { analytics } from 'src/helpers';

const SplashScreen = () => {
  return (
    <div className="flex items-center justify-center w-screen h-screen">
      <CircularProgress />
    </div>
  );
};

type InitialAuthState = {
  isAuthenticated: boolean;
  isInitialised: boolean;
  user?: UserProfileResponse;
  loading: boolean;
};

const initialAuthState: InitialAuthState = {
  isAuthenticated: false,
  isInitialised: false,
  loading: false,
};

const setSession = (accessToken: string | null) => {
  if (accessToken) {
    storage.set(AUTH_TOKEN_STORAGE_KEY, accessToken);
  } else {
    storage.remove(AUTH_TOKEN_STORAGE_KEY);
  }
};

const setShareSession = (shareToken: string | null) => {
  if (shareToken) {
    storage.set(SHARE_TOKEN_STORAGE_KEY, shareToken);
  } else {
    storage.remove(SHARE_TOKEN_STORAGE_KEY);
  }
};

const setUserIdentity = (user: UserProfileResponse) => {
  analytics.trackUserIdentify(user);
};

type Action = {
  type: string;
  payload: any;
};
const reducer = (state: any, action: Action) => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }

    case 'LOGIN': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        user,
        loading: false,
      };
    }

    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
      };

    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<{
  isAuthenticated: boolean;
  isInitialised: boolean;
  user?: UserProfileResponse;
  loading: boolean;
  login: (accessToken: string) => Promise<void>;
  logout: () => Promise<void>;
}>({
  ...initialAuthState,
  login: () => Promise.reject(new Error('Failed to login')),
  logout: () => Promise.reject(new Error('Failed to logout')),
});

export const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  // const apolloClient = useApolloClient();
  const { update: intercomUpdate, hide: intercomHide } = useIntercom();

  const initializeSupportFeedbackButton = useCallback(
    (user: UserProfileResponse) => {
      intercomUpdate({ name: user.name, email: user.email, userId: user.id });
    },
    [intercomUpdate],
  );

  const clearSupportFeedbackButton = () => {
    intercomHide();
  };

  const login = async (accessToken: string) => {
    dispatch({ type: 'SET_LOADING', payload: true });
    setSession(accessToken);

    try {
      const user = await fetchUserProfile(accessToken);
      setUserIdentity(user);

      initializeSupportFeedbackButton(user);

      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    } catch (error) {
      dispatch({ type: 'SET_LOADING', payload: false });
      throw error;
    }
  };

  const logout = async () => {
    setSession(null);
    setShareSession(null);

    clearSupportFeedbackButton();

    dispatch({
      type: 'LOGOUT',
      payload: {
        isAuthenticated: false,
      },
    });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        let shareID = new URLSearchParams(window.location.search).get(
          SHAREID_QUERY_PARAM,
        );
        if (shareID) {
          setShareSession(shareID);
        } else {
          shareID = storage.get(SHARE_TOKEN_STORAGE_KEY);
        }

        const accessToken = storage.get(AUTH_TOKEN_STORAGE_KEY);

        if (accessToken) {
          setSession(accessToken);
        }
        const combinedToken = accessToken ?? shareID;
        if (combinedToken) {
          const user = await fetchUserProfile(combinedToken);
          setUserIdentity(user);

          initializeSupportFeedbackButton(user);

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
          },
        });
      }
    };

    initialise();
  }, [initializeSupportFeedbackButton]);

  if (!state.isInitialised) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider value={{ ...state, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
