import { FC, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { Navigate, useLocation } from 'react-router-dom';

import useAuth from 'src/hooks/useAuth';

const AuthGuard: FC = ({ children }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const { clearStore } = useApolloClient();

  useEffect(() => {
    if (!isAuthenticated) {
      clearStore();
    }
  }, [clearStore, isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
