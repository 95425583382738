import { OutlinedInput } from '@material-ui/core';
import { HiOutlineSearch } from 'react-icons/hi';
import SelectPeriod from 'src/components/select-period';
import { ConnectFMCSA } from './ConnectFMCSA';

type SearchBarProps = {
  globalFilter: any;
  setGlobalFilter: (filterValue: any) => void;
  period: number;
  setPeriod: (period: number) => void;
  shouldShowConnectFMCSA: boolean;
};

export default function SearchBar({
  globalFilter,
  setGlobalFilter,
  period,
  setPeriod,
  shouldShowConnectFMCSA,
}: SearchBarProps) {
  return (
    <div className="flex flex-wrap items-center gap-2 mb-4 md:space-x-3">
      <OutlinedInput
        className="w-full lg:w-72"
        placeholder="Search by Violation code, VIN, etc."
        name="global-search"
        defaultValue={globalFilter}
        onChange={(e) => setGlobalFilter(e.target.value)}
        endAdornment={<HiOutlineSearch fontSize="1.2rem" color="#878B95" />}
      />
      <div className="flex-1" />
      <ConnectFMCSA shouldShowConnectFMCSA={shouldShowConnectFMCSA} />
      <SelectPeriod value={period} onChange={setPeriod} />
    </div>
  );
}
