import { analytics } from 'src/helpers';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  ClaimsByDotNumberQuery,
  useClaimsByDotNumberQuery,
  useDotNumberQuery,
} from 'src/types/graphql-types';
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table } from 'src/components/table/Table';
import { TablePagination } from '@material-ui/core';
import ClaimsImage from 'src/assets/images/claim.svg';
import { Feature, useFeatureEnabled } from 'src/helpers/feature-flags';
import { getColumns } from './constants/columns';

export const Claims = () => {
  const { reportId } = useParams<{ reportId: string }>();

  // Track analytics events
  useEffect(() => {
    analytics.trackPageView({
      name: analytics.SegmentEventTrack.ClaimsPageView,
    });
  }, []);

  const { data: dotInfo, loading: isDOTInfoLoading } = useDotNumberQuery({
    variables: { reportId: reportId as string },
  });

  const currentDot = dotInfo?.fleetSafetyReport?.dotNumber;
  const columns = useMemo(() => getColumns(), []);
  const showFeedback = useFeatureEnabled(Feature.CLAIMS_FEEDBACK);

  const { data: claims, loading: areClaimsLoading } = useClaimsByDotNumberQuery(
    {
      variables: { dotNumber: currentDot as string },
      skip: !currentDot,
    },
  );

  const table = useReactTable({
    columns,
    data:
      claims?.claimsByDOTNumber ??
      ([] as ClaimsByDotNumberQuery['claimsByDOTNumber']),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      sorting: [
        {
          id: 'status',
          desc: false,
        },
      ],
    },
  });
  const isLoading = isDOTInfoLoading || areClaimsLoading;

  useEffect(() => {
    table.setColumnVisibility({
      feedback: showFeedback,
    });
  }, [showFeedback, table]);

  return (
    <div>
      <header>
        <h1 className="text-3xl font-bold text-secondary-main">Claims</h1>
      </header>

      {!isLoading && table.getRowModel().rows.length === 0 ? (
        <div
          className="flex flex-col items-center justify-center p-4"
          data-testid="no-claims-found"
        >
          <div className="text-center">
            <p className=" text-secondary-main">
              <img src={ClaimsImage} className="m-auto" aria-hidden="true" />
              <h2 className="mt-8 mb-2 text-xl">
                You haven’t filed any claims
              </h2>
              <p className="text-text-hint">
                Report an incident or file a claim below.
              </p>
            </p>
          </div>
        </div>
      ) : (
        <div className="flex-1 mt-4 mb-4 overflow-auto bg-white rounded-md shadow">
          <Table table={table} loading={isLoading} />
          <div className="flex justify-start">
            <TablePagination
              data-testid="pagination"
              colSpan={3}
              rowsPerPageOptions={[10, 25, 50]}
              count={table.getFilteredRowModel().rows.length}
              page={table.getState().pagination.pageIndex}
              rowsPerPage={table.getState().pagination.pageSize}
              onPageChange={(_, page) => {
                table.setPageIndex(page);
              }}
              onRowsPerPageChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
