import { Button, FormControl, FormHelperText } from '@material-ui/core';
import { InputPassword, InputWithLabel } from '@nirvana/ui-kit';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { FaSpinner } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { useInitiateDriverViolationFetchLazyQuery } from 'src/types/graphql-types';
import LoginGov from 'src/assets/logos/login-gov.svg';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

type LoginProps = {
  onNextClick: () => void;
  setBrowserWSEndpoint: (browserWSEndpoint: string) => void;
  setTwoFactorUrl: (setTwoFactorUrl: string) => void;
};

const LoginSchema = z.object({
  email: z.string().email(),
  password: z.string().min(1),
});

type LoginType = z.infer<typeof LoginSchema>;

enum errorMessages {
  invalidCredentials = 'Invalid email/password',
  invalid2FA = 'Invalid 2FA code',
  unknownError = 'There was an error logging you in, please try again later',
}

export const Login = ({
  onNextClick,
  setBrowserWSEndpoint,
  setTwoFactorUrl,
}: LoginProps) => {
  const { reportId = '' } = useParams();

  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState<errorMessages>();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<LoginType>({
    mode: 'onChange',
    resolver: zodResolver(LoginSchema),
    defaultValues: { email: '', password: '' },
  });

  const [initiateQuery] = useInitiateDriverViolationFetchLazyQuery({
    onCompleted: (data) => {
      setIsProcessing(false);
      setError(undefined);
      if (!data?.initiateDriverViolationFetch?.browserWSEndpoint) {
        return;
      }
      onNextClick();
      setBrowserWSEndpoint(data.initiateDriverViolationFetch.browserWSEndpoint);
      setTwoFactorUrl(data.initiateDriverViolationFetch.twoFactorUrl);
    },
    onError: (error) => {
      setIsProcessing(false);
      const errorMessage = error.graphQLErrors[0] as unknown as string;
      reset();
      if (errorMessage === 'Invalid username/password') {
        setError(errorMessages.invalidCredentials);
        return;
      }
      setError(errorMessages.unknownError);
      console.error(error.graphQLErrors);
    },
  });

  const onSubmit = (data: any) => {
    setIsProcessing(true);
    setError(undefined);
    initiateQuery({
      variables: {
        reportId,
        username: data.email,
        password: data.password,
        // @ts-ignore
        random: Math.random, // Allow to send the same username/password again
      },
    });
  };

  return (
    <div>
      <form
        className="flex flex-col items-center py-10"
        onSubmit={handleSubmit(onSubmit)}
      >
        <img src={LoginGov} className="w-32 m-auto" />
        <p className="py-4">
          Please enter your login credentials for <strong>login.gov</strong> to
          unlock driver names for your fleet&apos;s violations.
        </p>

        <InputWithLabel
          {...register('email')}
          label="Email ID"
          formControlProps={{ fullWidth: true, className: 'mb-6' }}
          placeholder="Email"
          type="email"
          fullWidth
          helperText="Enter the email address associated with your login.gov account"
          error={!!errors.email}
        />

        <InputPassword
          {...register('password')}
          label="Password"
          formControlProps={{ fullWidth: true, className: 'mb-6' }}
          placeholder="Password"
          helperText="Enter the password associated with your login.gov account"
          fullWidth
          error={!!errors.password}
        />

        <FormHelperText className="mb-4" error>
          {error}
        </FormHelperText>

        <FormControl fullWidth>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            disabled={!isValid || isProcessing}
          >
            {isProcessing ? (
              <>
                <FaSpinner className="mr-2 animate-spin" /> Signing in...
              </>
            ) : (
              'Login'
            )}
          </Button>
        </FormControl>
      </form>
    </div>
  );
};
