import { SnackbarProvider } from 'notistack';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import {
  BrowserRouter,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import { IntercomProvider } from 'react-use-intercom';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { useLightTheme } from '@nirvana/ui-kit';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEffect } from 'react';
import Apollo from 'src/components/apollo';
import { AuthProvider } from 'src/contexts/auth';
import Logrocket from 'src/components/logrocket';
import LaunchDarkly from 'src/components/launch-darkly';
import { ANONYMOUS_USER_KEY } from 'src/helpers/feature-flags';
import { PostHog } from 'posthog-js';
import * as Sentry from '@sentry/react';
import AllRoutes from './routes';
import { Analytics } from './components/analytics/analytics';

// Define global Window to use segment all around the app
declare global {
  interface Window {
    analytics: any;
    posthog: PostHog;
  }
}

const queryClient = new QueryClient();
const INTERCOM_APP_ID = import.meta.env.VITE_INTERCOM_APP_ID || '';

const cache = createCache({
  key: 'css',
  prepend: true,
});

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.MODE,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/.+\.prod\.nirvanatech./],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

function App() {
  const theme = useLightTheme();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CacheProvider value={cache}>
        <SnackbarProvider
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <BrowserRouter>
            <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
              <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <Apollo>
                    <AllRoutes />
                  </Apollo>
                  <LaunchDarkly />
                  <Analytics />
                  {import.meta.env.PROD && <Logrocket />}
                </AuthProvider>
              </QueryClientProvider>
            </IntercomProvider>
          </BrowserRouter>
        </SnackbarProvider>
      </CacheProvider>
    </ThemeProvider>
  );
}

export default withLDProvider({
  clientSideID: import.meta.env.VITE_LAUNCHDARKLY_KEY,
  context: { kind: 'user', key: ANONYMOUS_USER_KEY },
})(App);
