import * as React from 'react';
import range from '../../utils/range';

type TableSkeletonLoaderProps = {
  rows?: number;
  columns: number;
};

export default function TableSkeletonLoader({
  rows = 5,
  columns,
}: TableSkeletonLoaderProps) {
  return (
    <>
      {range(rows).map((row) => (
        <tr key={row} className="">
          {range(columns).map((col) => (
            <td key={col} className="p-2">
              <div
                className="h-8 bg-gray-100 rounded animate-pulse"
                style={{ opacity: 1 - row / rows }}
              />
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
