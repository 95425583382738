/** Custom tick component for XAxis */
export default function CustomizedAxisTick(props: any) {
  const { x, y, payload } = props;
  if (!payload.value) {
    return null;
  }

  return (
    <g transform={`translate(${x}, ${y})`}>
      {payload.value.split(' ').map((word: string, index: number) => (
        <text
          key={index}
          x={0}
          y={10 + index * 18} // render each word 18 units below the previous one
          fontSize={12}
          fill="#363D4D"
          textAnchor="middle"
        >
          {word}
        </text>
      ))}
    </g>
  );
}
