import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { HiOutlineTag } from 'react-icons/hi';
import { CircularProgress } from '@material-ui/core';
import { useDotRatingQuery } from 'src/types/graphql-types';
import { dotRatingStatus } from '../constants/dot-rating';

export default function DotRating() {
  const { reportId = '' } = useParams();

  const { data, loading } = useDotRatingQuery({
    variables: { reportId },
  });

  const dotRating = data?.fleetSafetyReport?.DotRating;

  return (
    <div className="col-span-3 p-4 bg-white rounded-lg shadow">
      <div className="flex items-center mb-4 space-x-3">
        <div className="p-1 rounded bg-primary-extraLight text-primary-main">
          <HiOutlineTag />
        </div>
        <span className="font-semibold text-secondary-main">DOT Rating</span>
      </div>

      <div className="flex items-center justify-center h-56 py-4">
        {useMemo(() => {
          if (loading) {
            return <CircularProgress size={32} />;
          }
          if (dotRating) {
            const { icon: RatingIcon, status } = dotRatingStatus[dotRating];
            return (
              <div className="flex flex-col items-center justify-between h-full">
                <RatingIcon />
                <p className="text-xl font-semibold text-text-primary">
                  {status}
                </p>
              </div>
            );
          }
        }, [dotRating, loading])}
      </div>
    </div>
  );
}
