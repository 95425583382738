import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { TablePagination } from '@material-ui/core';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { Feature } from 'src/helpers/feature-flags';
import { useDOTSpecificFeature } from 'src/hooks/useDotSpecificFeature';
import {
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Table } from 'src/components/table/Table';
import { useTableQueryParams } from 'src/hooks/useTableQueryParams';
import {
  VehicleData,
  leaderboardColumns,
} from '../constants/leaderboard-columns';

type VehiclesLeaderboardProps = {
  isLoading: boolean;
  hasTelematicsConnection: boolean;
  vehicles?: Array<VehicleData>;
};

export default function VehiclesLeaderboard({
  isLoading,
  hasTelematicsConnection,
  vehicles = [],
}: VehiclesLeaderboardProps) {
  const { reportId = '' } = useParams();
  const { setSortBy, getSortBy } = useTableQueryParams();

  const { enabled: showRecommendation } = useDOTSpecificFeature(
    Feature.SHOW_RECOMMENDATION,
    {
      allowUserOverride: true,
    },
  );

  const columns = useMemo(
    () => leaderboardColumns(reportId, hasTelematicsConnection),
    [reportId, hasTelematicsConnection],
  );

  const table = useReactTable({
    columns,
    data: vehicles,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),

    autoResetPageIndex: false,
    initialState: {
      sorting: [],
    },
    state: {
      sorting: getSortBy(),
    },
    onSortingChange: setSortBy,
  });

  useEffect(() => {
    if (table.getState().sorting.length === 0) {
      table.setSorting([{ id: 'violationSeverityWeight', desc: true }]);
    }
  }, [table, setSortBy]);

  useEffect(() => {
    table.setColumnVisibility({
      recommendation: showRecommendation,
    });
  }, [showRecommendation, table]);

  return (
    <div className="p-4 mb-6 bg-white rounded-md shadow">
      <div className="flex items-center">
        <div className="p-2 mr-4 text-xl rounded bg-primary-extraLight text-primary-dark">
          <HiOutlineUserGroup />
        </div>
        <p className="text-xl font-semibold text-text-primary">Leaderboard</p>
      </div>
      <hr className="my-4 -mx-4" />

      <Table table={table} loading={isLoading} />
      <div className="flex justify-end">
        <TablePagination
          colSpan={3}
          rowsPerPageOptions={[10, 25, 50]}
          count={table.getFilteredRowModel().rows.length}
          page={table.getState().pagination.pageIndex}
          rowsPerPage={table.getState().pagination.pageSize}
          onPageChange={(_, page) => {
            table.setPageIndex(page);
          }}
          onRowsPerPageChange={(e) => {
            table.setPageSize(Number(e.target.value));
          }}
        />
      </div>
    </div>
  );
}
