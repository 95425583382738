import * as React from 'react';
import { Box, Grid, Typography, Link, Alert } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Show, InputWithLabel } from '@nirvana/ui-kit';
import { emptyForm, Form, validateSignInForm } from './validation';

export interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}

export interface InitialFormData {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface SignUpFormProps {
  error: string | null;
  signInLink: string;
  initialValues?: InitialFormData;
  onSubmit: (data: FormData) => void;
  title?: string;
}

const SignUpForm: React.FC<SignUpFormProps> = ({
  error,
  onSubmit,
  signInLink,
  initialValues = {},
  title = 'Complete sign up',
}) => {
  const [form, setForm] = React.useState<Form>(emptyForm(initialValues));
  const setField =
    (field: keyof Form) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setForm({
        ...form,
        [field]: { error: null, value: e.target.value, dirty: true },
      });
    };

  const validateForm = () => {
    setForm(validateSignInForm(form));
  };

  const wrappedOnSubmit = () => {
    const cleaned = Object.fromEntries(
      Object.entries(form).map(([key, value]) => [key, value.value]),
    ) as FormData;
    onSubmit(cleaned);
  };

  const isSubmitDisabled = Object.values(form).some(
    (field) => !field.value || field.error,
  );

  return (
    <div className="p-5">
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h5">{title}</Typography>
        </Grid>
        <Show when={!!error}>
          <Grid item>
            <Alert severity="error">{error}</Alert>
          </Grid>
        </Show>

        <Grid item>
          <Box component="form">
            <Grid container direction="column" alignItems="stretch">
              <Grid
                item
                container
                spacing={2}
                direction="row"
                alignItems="stretch"
              >
                <Grid item xs>
                  <InputWithLabel
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    id="firstName"
                    label="First Name"
                    value={form.firstName.value}
                    onChange={setField('firstName')}
                    onBlur={validateForm}
                    error={!!form.firstName.error}
                    helperText={form.firstName.error || undefined}
                  />
                </Grid>
                <Grid item xs>
                  <InputWithLabel
                    formControlProps={{
                      fullWidth: true,
                      required: true,
                    }}
                    id="lastName"
                    label="Last Name"
                    value={form.lastName.value}
                    onChange={setField('lastName')}
                    onBlur={validateForm}
                    error={!!form.lastName.error}
                    helperText={form.lastName.error || undefined}
                  />
                </Grid>
              </Grid>
              <Grid item>
                <InputWithLabel
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  id="email"
                  type="email"
                  label="Email"
                  value={form.email.value}
                  onChange={setField('email')}
                  onBlur={validateForm}
                  error={!!form.email.error}
                  helperText={form.email.error || undefined}
                />
              </Grid>

              <Grid item>
                <InputWithLabel
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  id="password"
                  label="Password"
                  type="password"
                  value={form.password.value}
                  onChange={setField('password')}
                  onBlur={validateForm}
                  error={!!form.password.error}
                  helperText={form.password.error || undefined}
                />
              </Grid>
              <Grid item>
                <InputWithLabel
                  id="confirmPassword"
                  formControlProps={{
                    fullWidth: true,
                    required: true,
                  }}
                  label="Confirm Password"
                  type="password"
                  value={form.confirmPassword.value}
                  onChange={setField('confirmPassword')}
                  onBlur={validateForm}
                  error={!!form.confirmPassword.error}
                  helperText={form.confirmPassword.error || undefined}
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitDisabled}
            onClick={wrappedOnSubmit}
            type="submit"
          >
            Create Account
          </Button>
        </Grid>
        <Grid item container alignItems="center" direction="column">
          <Link href={signInLink}>Sign in</Link>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignUpForm;
