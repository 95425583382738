import { IChipProps } from '@nirvana/ui-kit';

export type RiskScoreBucket = {
  name: string;
  graphFill: string;
  chipClass: IChipProps['color'];
  order: number;
  contains: Function;
};

export type RiskScoreChartRecord = {
  name: string;
  fill: string;
  count: number;
  percentage: number;
  order: number;
};

export function getBucketChipProps(
  buckets: Array<RiskScoreBucket>,
  value?: number | null,
): IChipProps {
  let chipClass: IChipProps['color'] = 'warning';
  buckets.some((bucket): boolean => {
    if (bucket.contains(value)) {
      chipClass = bucket.chipClass;
      return true;
    }
    return false;
  });
  return { color: chipClass, label: value };
}

function getPercentage(value: number, data: Array<{ riskScore?: any }>) {
  return Math.round((value * 100) / data.length);
}

export function getRiskScoreChartRecords(
  data: Array<{ riskScore?: any }>,
  buckets: Array<RiskScoreBucket>,
): Array<RiskScoreChartRecord> {
  const riskScores: Array<RiskScoreChartRecord> = buckets.map((record) => ({
    name: record.name,
    fill: record.graphFill,
    order: record.order,
    count: 0,
    percentage: 0,
  }));
  data?.forEach(({ riskScore }) => {
    buckets.some((bucket, index): boolean => {
      if (bucket.contains(riskScore)) {
        riskScores[index].count += 1;
        return true;
      }
      return false;
    });
  });

  return riskScores
    .map((record) => ({
      ...record,
      percentage: getPercentage(record.count, data),
    }))
    .sort((a, b) => {
      return a.order - b.order;
    });
}
