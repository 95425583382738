import { useEffect } from 'react';
import { useRoutes, useSearchParams } from 'react-router-dom';
import { GetRoutes } from './routes';

export default function AllRoutes() {
  const [searchParams, setSearchParams] = useSearchParams();
  const allRoutes = useRoutes(GetRoutes());

  // Handle UTM params
  useEffect(() => {
    const utmCookies: string[] = [];
    const nonUtmParams: { [key: string]: string } = {};

    // Create a cookie from the url's query params
    for (const entry of searchParams.entries()) {
      const [param, value] = entry;
      if (param.startsWith('utm_')) {
        utmCookies.push(`${param}=${value};`);
      } else {
        nonUtmParams[param] = value;
      }
    }

    // Don't set cookie if no utm params
    if (utmCookies.length === 0) {
      return;
    }

    // Clear UTM params
    setSearchParams(nonUtmParams);

    // expire after a week
    const timestamp = new Date().getTime(); // current time
    const exp = timestamp + 60 * 60 * 24 * 1000 * 7;

    utmCookies.forEach((cookie) => {
      document.cookie = `${cookie} Domain=nirvanatech.com; Path=/; expires=${exp}`;
    });
  }, [searchParams, setSearchParams]);

  return <>{allRoutes}</>;
}
