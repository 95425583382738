import { UserProfileResponse } from '@nirvana/api/auth';
import { useContext } from 'react';
import AuthContext from 'src/contexts/auth';

const useAuth = () => useContext(AuthContext);

export const isFleetUser = (user: UserProfileResponse | undefined) =>
  user?.userType === 'fleet' ||
  user?.userType === 'unprivileged' ||
  user?.userType === 'shared_link';

export default useAuth;
