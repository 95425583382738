import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { LineChart, TableTabs } from '@nirvana/ui-kit';
import { ReactComponent as ChartTrend } from 'src/assets/icons/chart-trend.svg';
import { useTrendsQuery } from 'src/types/graphql-types';
import { formatNumber } from 'src/helpers';
import { getLineChartProps, TabKeys, tabs } from '../constants/trends';

export default function Trends() {
  const { reportId = '' } = useParams();
  const [selectedTab, setSelectedTab] = useState<TabKeys>('pU');

  const { data: trends } = useTrendsQuery({
    variables: { reportId },
  });

  const chartData = useMemo(
    () =>
      trends?.fleetSafetyReport?.GeneralTrends.map((record) => ({
        [selectedTab]: record[selectedTab as keyof typeof record],
        date: format(new Date(record.year, record.month), 'MMM, yy'),
      }))
        .slice(0, 20)
        .reverse() ?? [],
    [trends?.fleetSafetyReport?.GeneralTrends, selectedTab],
  );

  const currentScore = {
    pU: 'Current Power Units',
    mileage: 'Recent Mileage',
    utilization: 'Current Utilization',
    score: chartData[chartData.length - 1]?.[selectedTab] as number,
  };

  return (
    <div className="col-span-7 p-4 bg-white rounded-lg shadow">
      <div className="flex items-center mb-4 space-x-3">
        <ChartTrend />
        <span className="font-semibold text-secondary-main">Trends</span>
      </div>

      <div className="flex items-center justify-between mb-4">
        <div className="inline-block">
          <TableTabs
            value={selectedTab}
            onChange={(value: string) => {
              setSelectedTab(value as TabKeys);
            }}
            displayCount={false}
            tabs={tabs}
          />
        </div>
        <p className="text-xs text-text-secondary">
          {currentScore[selectedTab]}:{' '}
          <span className="text-base font-medium text-success-main">
            {formatNumber(currentScore.score)}
          </span>
        </p>
      </div>

      <div className="h-64">
        <LineChart data={chartData} {...getLineChartProps(selectedTab)} />
      </div>
    </div>
  );
}
