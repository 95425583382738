import { Chip, Tooltip } from '@nirvana/ui-kit';
import { createColumnHelper } from '@tanstack/react-table';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import parseISO from 'date-fns/parseISO';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { ClaimsByDotNumberQuery, ClaimStatus } from 'src/types/graphql-types';
import log from 'src/utils/logger';
import useAuth from 'src/hooks/useAuth';
import { ClaimRating } from '../components/ClaimRating';

const StatusChip = ({ status }: { status: string }) => {
  if (status === 'Open') {
    return <Chip color="success" label="Open" />;
  }
  if (status === 'Closed') {
    return <Chip color="grey" label="Closed" />;
  }
  if (status !== 'Reopened') {
    log(`Unknown status: ${status}`, 'info');
  }
  return <Chip color="info" label={status} />;
};

const formatDate = (dateString: string) => {
  const date = parseISO(dateString);
  const today = new Date();
  if (Math.abs(differenceInDays(date, today)) > 7) {
    return format(date, 'MMM dd yyyy');
  }
  return formatDistance(date, today, { addSuffix: true });
};

export const getColumns = () => {
  const columnHelper =
    createColumnHelper<ClaimsByDotNumberQuery['claimsByDOTNumber'][0]>();
  return [
    columnHelper.accessor('externalId', {
      header: 'Claim Number',
      enableSorting: false,

      cell: ({ row, getValue }) => {
        return (
          <div>
            <Tooltip
              title={
                <div className="w-36">
                  <div className="flex justify-between">
                    <p> Policy ID</p>
                    <p>{row.original.policyNumber}</p>
                  </div>
                </div>
              }
            >
              <div className="flex justify-between">
                <strong>{getValue()}</strong>
                <span className="ml-2 text-base text-primary-main">
                  <HiOutlineInformationCircle />
                </span>
              </div>
            </Tooltip>
          </div>
        );
      },
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      sortingFn: (rowA, rowB) => {
        // Define the priority of statuses
        const statusPriority = {
          [ClaimStatus.Open]: 1,
          [ClaimStatus.Reopen]: 2,
          [ClaimStatus.Closed]: 3,
        } as { [key in ClaimStatus]: number };

        const statusA = rowA.original.status;
        const statusB = rowB.original.status;

        // Compare statuses based on their priority
        if (statusPriority[statusA] < statusPriority[statusB]) {
          return -1;
        }
        if (statusPriority[statusA] > statusPriority[statusB]) {
          return 1;
        }
        // If all else fails, sort by ID
        return rowA.original.externalId.localeCompare(rowB.original.externalId);
      },
      cell: ({ getValue }) => <StatusChip status={getValue()} />,
    }),
    columnHelper.accessor('lineOfBusiness', {
      header: 'Claim Type',
      enableSorting: false,
      cell: ({ getValue }) => {
        return <Chip color="tint" label={getValue()} />;
      },
    }),
    columnHelper.accessor('reportedBy', {
      header: 'Reported By',
      cell: ({ getValue }) => {
        return getValue()?.toUpperCase();
      },
    }),
    columnHelper.accessor('reportedAt', {
      header: 'Reported On',
      cell: ({ getValue }) => {
        return formatDate(getValue());
      },
    }),
    columnHelper.accessor('modifiedAt', {
      header: 'Last Action Taken',
      cell: ({ getValue }) => {
        return formatDate(getValue());
      },
    }),
    columnHelper.display({
      id: 'feedbacks[0].id',
      header: 'Feedback',
      cell: ({ row }) => {
        // This is a hook, but it can't be renamed to useCell, so we need to disable the rule
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { user } = useAuth();
        return (
          <ClaimRating
            userType={user?.userType}
            feedback={{
              ...row.original.feedbacks[0],
              claimId: row.original.id,
            }}
          />
        );
      },
      meta: {
        tooltipText:
          "Adjusters won't see your rating. Your feedback is used to improve your claims experience with Nirvana.",
      },
    }),
  ];
};
